<template>
<div>
<div class="card p-2 bg-card">
   <div class="align-center-flex">
        <div>
          <input
            v-model="searchResult"
            placeholder="Search"
            class="form-control input-sm mr-1"
          />
        </div>
        <div>
          <button
            v-b-toggle.sidebar-right
            @click="openSlideBar()"
            class="btn pointer btn-outline-primary btn-sm margin-right ml-1"
            :disabled="!this.$store.state.auth.permissions.includes('asset_category-create')"
          >
            + Add
          </button>
        </div>
      </div>
</div>
  <b-card>
    <div class="user-select">
     

      <div class="card-company-table card card-congratulation-medal">
        <div class="mb-0 table-responsive border rounded">
          <table role="table" class="table b-table table-hover">
            <thead role="rowgroup header">
              <tr role="row">
                <th role="columnheader" scope="col">#</th>
                <th role="columnheader" scope="col">Name</th>
                <!-- <th role="columnheader" scope="col">Description</th> -->
                <th role="columnheader" scope="col" class="text-align-right">
                  Action
                </th>
              </tr>
            </thead>
            <tbody class="rowgroup">
              <tr
                v-for="(assets, index) in allAssets"
                :key="assets._id"
                role="row"
                class="custom-table-row"
              >
              <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                <td role="cell">
                  {{ assets.name }}
                </td>
                <!-- <td role="cell">
                  {{ assets.description }}
                </td> -->

                <td
                  role="cell"
                  class=" text-align-right"
                >
                  <span
                    @click="detailAsset(assets._id)"
                    class="icon-box-new-new pointer"
                  >
                    <feather-icon class="rotate" icon="EyeIcon" size="20" />
                  </span>
                  <!-- <span v-b-toggle.sidebar-right @click="editAssets(assets)" class="icon-box-new-new pointer">
                                    <feather-icon class="  rotate" icon="Edit2Icon" size="20" />
                                </span> -->
                  <span
                    @click="showModal(assets._id)"
                    class="icon-box-new-new pointer"
                   
                  >
                    <feather-icon
                      class="delete-color rotate"
                      icon="Trash2Icon"
                      size="20"
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="card-body">
            <b-modal
              id="modal-danger"
              ref="modal-danger"
              ok-only
              ok-variant="danger"
              @ok="deleteAssets()"
              modal-class="modal-danger"
              centered
              title="Delete !"
              :ok-disabled="!this.$store.state.auth.permissions.includes('asset_category-delete')"
            >
              <b-card-text> Are You Sure to Delete the Asset ? </b-card-text>

              <!-- <button @click="deleteUser(item._id)"></button> -->
            </b-modal>
          </div>
        </div>
      </div>
      <div class="float-right">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        >
        </b-pagination>
      </div>
    </div>
  </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import {
  BFormTextarea,
  BSidebar,
  VBToggle,
  BPagination,
  BCard,
  BModal,
} from "bootstrap-vue";
// import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data: () => ({
    allAssets: [],
    assetsDescription: "",
    assetName: "",
    addAssetCategories: false,
    editAssetCategories: false,
    editAssetID: "",
    hideSideBar: false,
    duplicateName: false,
    currentPage: 1,
    lastPage: null,
    perPage: 10,
    rows: 1,
    searchResult: "",
    userid: "",
  }),
  components: {
    BFormTextarea,
    BSidebar,
    BPagination,
    BCard,
    BModal,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {},
  watch: {
    currentPage(newPage) {
      this.getAssets(newPage);
    },
    searchResult() {
      this.searchResultData(this.currentPage);
    },
  },
  mounted() {
    this.getAssets(this.currentPage);
  },
  computed: {},
  methods: {
    showModal(itemid) {
      this.userid = itemid;
      this.$refs["modal-danger"].show();
    },
    detailAsset(id) {
      this.$router.push({ name: "assetdetails", params: { id: id } });
    },
    searchResultData(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_ONECSF_API_URL +
            `/ast_categories?name=${this.searchResult}`,
          params: { page: num },
        };
        this.$http(options)
          .then((res) => {
            this.allAssets = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    cancel() {
      this.hideSideBar = false;
      this.assetName = "";
      this.assetsDescription = "";
    },
    openSlideBar() {
      this.$router.push({ name: "addassets" });
    },
    getAssets(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories`,
          params: { page: num },
        };
        this.$http(options)
          .then((res) => {
            this.allAssets = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
            console.log(this.currentPage, this.lastPage, this.rows);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteAssets(assetID) {
      assetID = this.userid;
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories/${assetID}`,
      };
      this.$http(options)
        .then((res) => {
          this.getAssets();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editAssets(asset) {
      this.hideSideBar = true;
      this.editAssetCategories = true;
      this.addAssetCategories = false;
      this.editAssetID = asset._id;
      this.assetName = asset.name;
      this.assetsDescription = asset.description;
    },
    updateAsset() {
      let data = {
        name: this.assetName,
        description: this.assetsDescription,
      };
      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/ast_categories/${this.editAssetID}`,
      };
      this.$http(options)
        .then((res) => {
          this.getAssets();
          this.assetName = "";
          this.assetsDescription = "";
          (this.editAssetCategories = false), (this.hideSideBar = false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addAssets() {
      const body = {
        name: this.assetName,
        description: this.assetsDescription,
      };
      const duplicateData = this.allAssets.filter(
        (ele) => ele.name.toLowerCase() == this.assetName.toLowerCase()
      );
      if (!duplicateData.length) {
        const options = {
          method: "POST",
          data: body,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories`,
        };
        this.$http(options)
          .then((res) => {
            this.getAssets();
            this.hideSideBar = !this.hideSideBar;
            this.duplicateName = false;
            this.assetName = "";
            this.assetsDescription = "";
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.duplicateName = true;
      }
    },
  },
};
</script>

<style>
.transpent {
  background-color: rgba(130, 134, 139, 0.12) !important;
}
.header {
  font-size: 20px;
  font-weight: 600;
}
.align-center-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.align-center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.padding-20 {
  padding: 20px;
}
/* .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #f3f2f7 !important;
  color: #6e6b7b;
} */
.align-right {
  display: flex;
  justify-content: flex-end;
}
.icon-box-new-new {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}
.delete-color {
  color: #ea5455 !important;
}
.view-color {
  color: #28c76f !important;
}
.mr-bottom {
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}
.validation {
  color: red;
  font-size: 12px;
  position: relative;
  top: -10px;
}
.table th,
.table td {
  border-top: 1px solid #e3e0e9;
}
.rowgroup tr:hover {
  background-color: #f6f6f9;
  cursor: pointer !important;
}
.text-align-right {
  text-align: right;
}
.modal .modal-header .close {
  position: relative;
  top: 4px;
  left: -10px;
}
.user-select {
  user-select: none;
}
th{
  background: #472183 !important;
  color: #fff;
}
</style>
